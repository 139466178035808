const search = instantsearch({
  indexName: "ss-profile",
  searchClient: instantMeiliSearch(
    "https://ms.pseud0.net/",
    "c250acc7977cf2e1f44b2a4a868cca238822825f72b0dcb6ee893f0823a66ea8",
    {
      limitPerRequest: 30
    }
  )
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: "#searchbox"
  }),
  
  instantsearch.widgets.clearRefinements({
    container: "#clear-refinements"
  }),
  instantsearch.widgets.refinementList({
    container: "#suspect-list",
    attribute: "suspect",
    searchable: true,
    showMore: true,
    searchablePlaceholder: "search suspects",
    limit: 10
  }),
  
  instantsearch.widgets.refinementList({
    container: "#badge-list",
    attribute: "badge"
  }),/*
  instantsearch.widgets.configure({
    hitsPerPage: 6,
    snippetEllipsisText: "...",
    attributesToSnippet: ["description:50"]
  }),
  instantsearch.widgets.refinementList({
    container: "#misc-list",
    attribute: "misc"
  }),*/
  instantsearch.widgets.hits({
    container: "#hits",
    templates: {
      item: `
        <div>
          <div class="hit-name">
            {{#helpers.highlight}}{ "attribute": "username" }{{/helpers.highlight}}
          </div>
          <img alt="avatar" src="https://pseud0.net/d/parler-avatars/{{avatar_url}}" align="left" />
          <div class="hit-description">
          {{#helpers.snippet}}{ "attribute": "bio" }{{/helpers.snippet}}
        </div>
        <div class="hit-info">
        <strong>matched suspect name: </strong> <a href="{{sus_url}}">{{suspect}}</a>
     </div>
        <div class="hit-info">
         {{name}} - <strong>{{badge}}</strong>
      </div>
          <div class="hit-info">
            <a rel="norefer noindex" target="_blank" href="{{wayback_url}}">wayback ></a>
          </div>
          
        </div>
      `
    }
  }),
  instantsearch.widgets.pagination({
    container: "#pagination"
  })
]);

search.start();
